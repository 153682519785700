<template>
  <header class="header-container">
    <a href="/" class="logo">BOOSTER</a>
    <!-- <div class="logo">BOOSTER</div> -->
    <!-- <nav>
      <ul class="nav-list">
        <li><router-link to="/" active-class="active-link">Главная</router-link></li>
        <li><router-link to="/about" active-class="active-link">О проекте</router-link></li>
        <li><router-link to="/rules" active-class="active-link">Правила</router-link></li>
      </ul>
    </nav> -->
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent'
};
</script>





<style scoped>
.header {
  background: radial-gradient(circle, #1e1e2f, #000);
  color: white;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  font-family: 'Orbitron', sans-serif;
  font-size: 3.5rem;
  font-weight: bold;
  color: white;
  animation: glow-wave 3s infinite;

}
.logo:hover {
  text-decoration: underline;
}

@keyframes glow-wave {
  0%, 100% {
    text-shadow: 0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 30px #800080;
  }
  25% {
    text-shadow: 0 0 15px #ff00ff, 0 0 30px #ff00ff, 0 0 45px #800080;
  }
  50% {
    text-shadow: 0 0 20px #ff00ff, 0 0 40px #ff00ff, 0 0 60px #800080;
  }
  75% {
    text-shadow: 0 0 15px #ff00ff, 0 0 30px #ff00ff, 0 0 45px #800080;
  }
}

.nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.nav li {
  margin: 0 1.5rem;
}
.nav a {
  font-family: 'Poppins', sans-serif;
  color: #bbb;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s, text-decoration 0.3s;
}
.nav a:hover {
  color: #ff00ff;
  text-shadow: 0 0 5px #ff00ff, 0 0 10px #ff00ff;
  text-decoration: underline; /* Добавляем подчеркивание при наведении */
}
</style>

