<template>
  <div class="timer-container">
    <div class="prize-image">
      <img
        :src="prizeImage"
        :alt="prizeDescriptoion"
        class="prize-img"
        :title="prizeDescriptoion"
      />
    </div>
    <div class="timer">
      <h2>
        {{ formattedDays }}:{{ formattedHours }}:{{ formattedMinutes }}:{{ formattedSeconds }}
      </h2>
    </div>
    <div class="description">
      Requirements:
      <p>✔️ Subscribe to our <a :href="instagramUrl" target="_blank" class="gift-link">Instagram</a></p>
      <p>✔️ Guess an integer. The minimum unique number closest to one wins.</p>
      <br>
    </div>
    <p class="participates">🧑‍🤝‍🧑 Participates: {{ participantsCount }}</p>

    <div class="play-button-container">
      <button @click="goToPlay" class="play-button circular-button">Register</button>
    </div>
  </div>
</template>

<script>
import eventData from "../../data/event-data.json";
// import participantsData from "/data/events/event_1.json";

export default {
  name: "CountdownTimer",
  props: {
    targetDate: {
      type: String,
      required: true, // Указывает, что параметр обязателен
    },
  },
  data() {
    return {
    targetTime: new Date(this.targetDate), // Используем targetDate из props
    giftName: eventData.event.next.gift.name,
    giftLink: "/gift",
    prizeImage: eventData.event.next.gift.image,
    prizeDescriptoion: eventData.event.next.gift.description,
    participantsCount: 0, // Начальное значение
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    intervalId: null,
    instagramUrl: process.env.VUE_APP_INSTAGRAM_CONTACT
  };
  },
  computed: {
    formattedDays() {
      return String(this.days).padStart(2, "0");
    },
    formattedHours() {
      return String(this.hours).padStart(2, "0");
    },
    formattedMinutes() {
      return String(this.minutes).padStart(2, "0");
    },
    formattedSeconds() {
      return String(this.seconds).padStart(2, "0");
    },
  },
  created() {
    this.startCountdown();
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    startCountdown() {
      this.updateTime();
      this.intervalId = setInterval(this.updateTime, 1000);
    },
    updateTime() {
      const now = new Date();
      const timeDiff = this.targetTime - now;

      if (timeDiff > 0) {
        this.days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        this.hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      } else {
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
        clearInterval(this.intervalId);

        console.log("The event has finnished!");
        console.log("Time is up!");
        this.$emit('time-up'); // Сообщаем родительскому компоненту

        // this.$router.push("/");
      }
    },
    goToPlay() {
      // this.registrationSuccess = true;
      setTimeout(() => {
        this.$router.push("/play");
      }, 1000);
    },

    async fetchParticipants() {
      try {
        const response = await fetch('/api/participants'); // Ваш эндпоинт
        const data = await response.json();
        this.participantsCount = data.count; // Обновляем значение
      } catch (error) {
        console.error('Ошибка при получении количества участников:', error);
      }
    },
  },
  mounted() {
    this.fetchParticipants(); // Вызов при монтировании компонента
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Digital-7&display=swap");

.timer-container {
  padding: 20px;
  border-radius: 10px;
  background-color: #0e0106eb;
  text-align: center;
  max-width: 490px;
  margin: 0 auto;
  font-family: "Arial", sans-serif;
}

.prize-image {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.prize-image::after {
  content: "PRIZE";
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
  color: rgb(74, 50, 85);
  text-shadow: 0 0 10px rgb(41, 100, 226), 0 0 20px rgb(247, 0, 255), 0 0 30px #ff0, 0 0 40px #34b81a, 0 0 50px #ff0055;
  /* font-family: "Comic Sans MS", cursive, sans-serif; */
  pointer-events: none;
}

.prize-img {
   max-width: 100%; /* Уменьшение размера изображения */
  height: auto;
  border-radius: 10px;
}

.timer {
  font-size: 2em;
  margin-bottom: 15px;
  font-family: "Digital-7", monospace;
  color: rgb(255, 255, 255)
  /* color: rgb(0 255 255) */
}

.description {
  font-size: 0.9em;
  text-align: left;
  color: #555;
  line-height: 1.5;
}
.description p{
  font-size: 0.9em;
  text-align: left;
  padding-left: 0.5em;
  margin: 0.1em 0;
  color: #555;
}
.participates {
  font-size: 0.9em;
  text-align: left;
  /* padding-left: 0.5em; */
  /* margin: 0.1em 0; */
  color: #555;
}

.play-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.play-button {
  background: transparent;
  color: #ffffff;
  /* color: #00d1ff; */
  border: 2px solid #f942ff;
  padding: 10px 20px;
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s, border-color 0.3s;
}

.play-button:hover {
  /* transform: scale(1.1); */
  border-color: #ff2cdd;
  /* border: 5px solid #f0f; */
  /* background: linear-gradient(45deg, #f0953320, #e6693c20, #dc274320,#dc27a620, #b823cc20, #9618bc20); */
}


.circular-button {
  /* background-color: #000; */
  border: 3px solid #f0f;
  border-radius: 50%;
  text-align: center;
  color: rgb(224, 224, 224);
  /* color: #0ff; */
  font-size: 1.2rem;
  /* transition: all 0.3s ease; */
  padding: 30px;
}

.success-message {
  color: #28a745;
  font-weight: bold;
  margin-top: 15px;
}

.gift-link {
  color: #c799c1;
  text-decoration: none;
  background: linear-gradient(45deg, #f09433, #e6683c, #dc2743,#dc27a6, #b823cc, #9618bc);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 200% 200%;
  animation: gradientAnimation 3s infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
