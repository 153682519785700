<template>
  <div class="not-found-container">
    <h1>404 - Page not found</h1>
    <p>Unfortunately, the page you requested does not exist..</p>
    <BackLink/>
  </div>
</template>

<script>
import BackLink from '../components/shared/BackLink.vue';

export default {
  name: 'PageNotFound',
  components: {
    BackLink,
  },
};
</script>

<style scoped>
.not-found-container {
  padding: 20px;
  margin: 40px auto; /* Увеличенные отступы для центрирования */
  border-radius: 10px;
  background-color: #000918;
  max-width: 490px;
  text-align: center;
  font-family: "Arial", sans-serif;
  color: #fff6f6;
}

.not-found-container h1 {
  color: #f8f3f4;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.not-found-container p {
  font-size: 1.2em;
  margin-bottom: 20px;
}


</style>
