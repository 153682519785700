<template>
  <a href="javascript:void(0)" @click="goBack" class="back-link">
    {{ text }}
  </a>
</template>

<script>
export default {
  name: 'BackLink',
  props: {
    text: {
      type: String,
      default: '← Go Back', // Текст по умолчанию
    },
  },
  methods: {
    goBack() {
      window.history.back();
    },
  },
};
</script>


<style scoped>

.back-link {
  display: inline-block;
  margin-top: 20px;
  color: #00d1ff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.back-link:hover {
  color: #f942ff;
}
</style>