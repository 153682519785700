import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/global.css'; // импорт глобального CSS

import { createI18n } from 'vue-i18n';
import rulesRu from './locales/rules/ru.json';
import rulesEn from './locales/rules/en.json';
import rulesGe from './locales/rules/ge.json';


const messages = {
  ru: { rules: rulesRu },
  en: { rules: rulesEn },
  ge: { rules: rulesGe },
};

const i18n = createI18n({
  locale: 'en', // Язык по умолчанию
  fallbackLocale: 'ge',
  messages,
});


createApp(App)
  .use(router)
  .use(i18n)
  .mount('#app');
