<template>
  <div class="home-view">
    <component 
      :is="currentComponent" 
      @time-up="handleTimeUp" 
      :targetDate="targetDate"
      v-bind="currentComponentProps"
    />
  </div>
</template>

<script>
import CountdownTimer from '../components/CountdownTimer.vue';
import WinnerInfo from '../components/WinnerInfo.vue';
import eventData from "../../data/event-data.json";
console.log(" ---- ")
console.log(new Date(eventData.event.next.date))

export default {
  name: 'HomeView',
  components: {
    CountdownTimer,
    WinnerInfo,
  },
  data() {
    return {
      currentComponent: 'CountdownTimer',
      targetDate: new Date(eventData.event.next.date),
      winnerData: {
        name: 'John Doe',
        magicNumber: 42,
        prize: 'Portable Speaker',
      }
    };
  },
  computed: {
    currentComponentProps() {
      // Передаём пропы в зависимости от отображаемого компонента
      return this.currentComponent === 'WinnerInfo'
        ? { winnerData: this.winnerData }
        : {};
    },
  },
  methods: {
  handleTimeUp() {
    console.log('Switching to WinnerInfo component');
    this.currentComponent = 'WinnerInfo'; // Переключаем компонент на WinnerInfo
  },
  }
};

</script>

<style scoped>
.home-view {
  padding-left: 20px; 
  padding-right: 20px;
  text-align: center;
}
</style>