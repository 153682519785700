<template>
  <div class="result-game">
    <h1>Congratulations to the Winner!</h1>
    <div v-if="winnerData">
      <p v-for="participant in participants.filter(participant => participant.winner)" :key="participant.username">🚀<strong>Instagram:</strong><a class="result-game" :href="`https://instagram.com/${participant.username}/`"> {{ participant.username }}</a></p>

      <!-- <a :href="`https://instagram.com/${participant.username}/`" target="_blank"> {{ participant.username }}</a> -->

      <p v-for="participant in participants.filter(participant => participant.winner)" :key="participant.userAnswer">💫<strong>Magic number:</strong> {{ participant.userAnswer }}</p>
      <!-- <p>🏆<strong>Prize:</strong> {{ winnerData.prize }}</p> -->
      <p >👾<strong>Total Participants:</strong> {{ participants.length }}</p>

    <div>
      <h2>Participants</h2>
      <div class="table-container">
        <table border="1">
          <thead>
            <tr>
              <th>Username</th>
              <th>Number</th>
              <!-- <th>Timestamp</th> -->
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="participant in participants" :key="participant.username" :style="{ backgroundColor: participant.winner ? '#690470' : 'inherit' }">
              <td>{{ participant.username }}</td>
              <td>{{ participant.userAnswer }}</td>
              <!-- <td>{{ participant.timestamp }}</td> -->
              <td>{{ participant.winner ? '🚀Winner🚀' : 'Participant' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


    <div v-else>
      <p>Loading winner information</p>
      <div class="loading-dots">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      winnerData: null,
      participants: [],
    };
  },
  created() {
    this.fetchParticipants();
  },
  methods: {
    async fetchParticipants() {
      try {
        const response = await fetch(`/api/result-game/`);
        const result = await response.json();
        this.participants = result.data;
        this.winnerData = this.participants.find(participant => participant.winner) || null;
      } catch (error) {
        console.error('Error fetching participants:', error);
      }
    },
  },
};
</script>

<style scoped>
/* .result-game {
  text-align: center;
  margin: 20px;
} */

.result-game h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.result-game p {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.result-game {
  padding: 20px;
  margin: 20px auto;
  border-radius: 10px;
  background-color: #000918;
  max-width: 800px;
  /* max-width: 490px; */

  text-align: left;
  font-family: "Arial", sans-serif;
  color: #fff6f6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.loading-dots div {
  width: 8px;
  height: 8px;
  background-color: #3498db;
  border-radius: 50%;
  animation: bounce 1.5s infinite;
}

.loading-dots div:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}



h1 {
    text-align: center;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  th, td {
    padding: 5px;
    text-align: left;

    border: 1px solid #ddd;
    padding: 8px;
    max-width: 200px; /* Установите максимальную ширину ячейки */
    white-space: nowrap; /* Убирает перенос строк */
    overflow: hidden; /* Скрывает содержимое за границей */
    text-overflow: ellipsis; /* Добавляет троеточие в конце */

    text-align: left;
  }
  th {
    background-color: #000000;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .table-container {
    overflow-x: auto; /* Включает горизонтальный скролл */
    white-space: nowrap; /* Убирает перенос строк в ячейках таблицы */
    border: 1px solid #ddd; /* Граница для контейнера */
  }
</style>
