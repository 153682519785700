<template>
  <div id="app">
    <HeaderComponent />
    <main>
      <router-view />
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from './components/Footer.vue';
import HeaderComponent from './components/Header.vue';

export default {
  name: 'App',
  components: {
    FooterComponent,
    HeaderComponent
  }
};
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  background: radial-gradient(#550d39, #270537);
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

header {
  /* background: #007bff; */
  color: white;
  padding: 10px;
  text-align: center;
}

header a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

header a:hover {
  text-decoration: underline;
}
</style>
