<template>
  <footer class="footer-container">
    <div class="footer-contacts">
      <ul class="social-links">
        <li>
          <a :href="instagramUrl" target="_blank" aria-label="Instagram" class="glowing-icon instagram">
            <i class="fab fa-instagram"></i>
          </a>
        </li>
        <li v-if="showWhatsApp">
          <a :href="whatsappUrl" target="_blank" aria-label="WhatsApp" class="glowing-icon whatsapp disabled" >
            <i class="fab fa-whatsapp" disabled></i>
          </a>
        </li>
        <li v-else>
          <a href="#" target="_blank" aria-label="WhatsApp " class="glowing-icon whatsapp disabled" >
            <i class="fab fa-whatsapp" disabled></i>
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data() {
    return {
      whatsappUrl: process.env.VUE_APP_WHATSAPP_CONTACT,
      instagramUrl: process.env.VUE_APP_INSTAGRAM_CONTACT,
      showWhatsApp: false,
    };
  },
  mounted() {
  // console.log('WhatsApp URL:', this.whatsappUrl);
  // console.log('instagramUrl URL:', this.instagramUrl);
}
};
</script>

<style scoped>
.footer-container {
  color: white;
  text-align: center;
  padding: 15px;
  width: 100%;
}

.footer-contacts {
  margin-top: 10px;
}

.social-links {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.glowing-icon {
  display: inline-block;
  font-size: 2rem;
  color: white;
  text-decoration: none;
  position: relative;
}

.glowing-icon i {
  position: relative;
  z-index: 1;
}

/* Instagram-specific glow */
.instagram::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: conic-gradient(
    #f58529,
    #dd2a7b,
    #8134af,
    #515bd4,
    #f58529
  );
  filter: blur(10px);
  animation: instagram-glow 3s linear infinite;
  z-index: 0;
}

.instagram:hover::before {
  filter: blur(15px);
}

/* WhatsApp-specific glow */
.whatsapp::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* background: radial-gradient(circle, #a1cfb2, #128c7e, #075e54); */
  filter: blur(10px);
  animation: whatsapp-glow 3s linear infinite;
  z-index: 0;
}

.whatsapp:hover::before {
  filter: blur(15px);
}

/* Instagram animation */
@keyframes instagram-glow {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* WhatsApp animation */
@keyframes whatsapp-glow {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.disabled {
  pointer-events: none;
  color: grey;
  text-decoration: none;
}

</style>
