<template>
  <div class="gift-container">
    <!-- <h1>Подарок розыгрыша</h1>
    <p>В данном розыгрыше мы разыгрываем <a :href="giftLink" class="gift-link" target="_blank">{{ giftName }}</a>.</p>
    <p>{{ giftDescription }}</p>
    <p>Примите участие в розыгрыше и получите шанс выиграть этот замечательный приз!</p> -->
    <p><a href="#" class="back-link" @click.prevent="goBack">← back</a></p>
  </div>
</template>

<script>
import eventData from '../../data/event-data.json';

export default {
  name: 'GiftView',
  data() {
    return {
      giftName: eventData.event.next.gift.name,
      giftDescription: eventData.event.next.gift.description,
      giftLink: eventData.event.next.gift.link
    };
  },
  methods: {
    goBack() {
      window.history.back();
    }
  },
};
</script>

<style scoped>
.gift-container {
  padding: 20px;
  text-align: left;
  background-color: #f8f9fa;
  border: 2px solid #343a40;
  border-radius: 10px;
  max-width: 800px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gift-container h1 {
  text-align: center;
  color: #343a40;
  margin-bottom: 20px;
}

.gift-container p {
  font-size: 1.2em;
  margin-bottom: 10px;
  line-height: 1.5;
}

.gift-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.gift-link:hover {
  color: #0056b3;
}
</style>
