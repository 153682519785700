<template>
  <div class="play-container">
    <h1>Game Time</h1>
    <form @submit.prevent="registerPlayer" class="registration-form">
      <div>
        <input type="text" v-model="username" required placeholder="Instagram"/>
      </div>
      <div>
        <input type="number" v-model="userAnswer" required placeholder="You number"/>
      </div>
      <div class="form-group agreement-group">
  <label for="agreement" class="agreement-label">
    <input type="checkbox" id="agreement" v-model="agreement" required /> I got acquainted with the <a href="/rules" class="rules-link">rules</a> of the game </label>
      </div>
      <div class="play-button-container">
      <button type="submit" class="play-button circular-button">Register</button>
    </div>
    </form>

  </div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
  name: "PlayView",
  data() {
    return {
      username: '',
      userAnswer: null,
      agreement: false
    };
  },
  methods: {
    async registerPlayer() {

      try {

        // Логика регистрации игрока
        if (!this.agreement) {
          Swal.fire({
            title: 'Ошибка',
            text: 'Вы должны ознакомиться с правилами игры',
            // icon: 'error',
            confirmButtonText: 'OK'
          });
          return;
        }

        const response = await fetch(`/api/add-participant`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: this.username,
            userAnswer: this.userAnswer
          })
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.error;

          console.error('Ошибка при добавлении участника:', errorMessage);
          throw new Error(`${errorMessage}`);
        } else {
          // вывод сообщения об успешной регистрации
          Swal.fire({
            title: 'Success!',
            text: 'You have successfully registered!!!',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            // Переход на главную страницу
            this.$router.push('/');
          });

          console.log('Регистрация игрока:', this.username, this.userAnswer);
          // Отображение попап сообщения об успешной регистрации
        }
      } catch(e) {
        Swal.fire({
            title: 'Error!',
            text: `${e}`,
            icon: 'Error',
            confirmButtonText: 'OK'
          })
        return;
      }
      
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Digital-7&display=swap");

html, body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.play-container {
  padding: 20px;
  margin: 40px auto; /* Увеличенные отступы для центрирования */
  border-radius: 10px;
  background-color: #000918;
  max-width: 490px;
  text-align: center;
  font-family: "Arial", sans-serif;
  color: #fff6f6;
}

@media (max-width: 768px) {
  .play-container {
    max-width: 90%;
  }
}

h1 {
  font-size: 2.5rem;
  padding: 15px;
}

p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
}

.play-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

form div {
  margin-bottom: 15px;
  text-align: left;
}

form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

form input[type="text"], form input[type="number"] {
  width: 100%;
  padding: 8px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.play-button {
  background: transparent;
  color: #00d1ff;
  border: 2px solid #f942ff;
  padding: 10px 20px;

  font-weight: bold;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s, border-color 0.3s;
}

.play-button:hover {
  border-color: #ff2cdd;
}

.circular-button {
  border: 3px solid #f0f;
  border-radius: 50%;
  text-align: center;
  color: rgb(224, 224, 224);
  font-size: 1.2rem;
}
</style>
