<template>
  <div class="rules-container">

    <div class="language-buttons">
      <button class="lang-button" @click="$i18n.locale = 'en'">EN</button>
      <button class="lang-button" @click="$i18n.locale = 'ge'">GE</button>
      <button class="lang-button" @click="$i18n.locale = 'ru'">RU</button>
    </div>

    <h1>{{ $t('rules.title') }}</h1>

    <h3>{{ $t('rules.general') }}</h3>
    <p>{{ $t('rules.generalPoints[0]') }}</p>
    <p>{{ $t('rules.generalPoints[1]') }}</p>
    <p>{{ $t('rules.generalPoints[2]') }}</p>

    <h3>{{ $t('rules.participation') }}</h3>
    <p>{{ $t('rules.participationPoints[0]') }}</p>
    <ul>
      <li>{{ $t('rules.participationPoints[1]') }}</li>
      <li>{{ $t('rules.participationPoints[2]') }}</li>
    </ul>
    <p>{{ $t('rules.participationPoints[3]') }}</p>

    <h3>{{ $t('rules.orderOfParticipation') }}</h3>
    <p>{{ $t('rules.orderOfParticipationPoints[0]') }}</p>
    <p>{{ $t('rules.orderOfParticipationPoints[1]') }}</p>
    <p>{{ $t('rules.orderOfParticipationPoints[2]') }}</p>

    <h3>{{ $t('rules.determiningTheWinner') }}</h3>
    <p>{{ $t('rules.determiningTheWinnerPoints[0]') }}</p>
    <p>{{ $t('rules.determiningTheWinnerPoints[1]') }}</p>
    <p>{{ $t('rules.determiningTheWinnerPoints[2]') }}</p>

    <h3>{{ $t('rules.receivingPrize') }}</h3>
    <p>{{ $t('rules.receivingPrizePoints[0]') }}</p>
    <p>{{ $t('rules.receivingPrizePoints[1]') }}</p>
    <p>{{ $t('rules.receivingPrizePoints[2]') }}</p>

    <h3>{{ $t('rules.responsibilityAndOtherThings') }}</h3>
    <p>{{ $t('rules.responsibilityAndOtherThingsPoints[0]') }}</p>
    <p>{{ $t('rules.responsibilityAndOtherThingsPoints[1]') }}</p>
    <p>{{ $t('rules.responsibilityAndOtherThingsPoints[2]') }}</p>
    <p>{{ $t('rules.responsibilityAndOtherThingsPoints[3]') }}</p>

    <BackLink :text="$t('rules.back')" />
  </div>
</template>

<script>
import BackLink from '../components/shared/BackLink.vue';

export default {
  name: 'RulesView',
  components: {
    BackLink,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Digital-7&display=swap");

.rules-container {
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
  background-color: #000918;
  max-width: 800px;
  text-align: left;
  font-family: "Arial", sans-serif;
  color: #fff6f6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .rules-container {
    max-width: 90%;
  }
}

.rules-container h1 {
  text-align: center;
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 20px;
}

.rules-container h3 {
  color: #ffffff;
  margin-top: 20px;
  font-size: 1.5rem;
}

.rules-container p, .rules-container ul {
  font-size: 1rem;
  color: #cccccc;
  margin-bottom: 10px;
  line-height: 1.5;
}

.rules-container ul {
  padding-left: 20px;
}

.rules-container li {
  margin-bottom: 5px;
  color: #cccccc;
}

.gift-link {
  color: #00d1ff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.gift-link:hover {
  color: #f942ff;
}


.language-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.lang-button {
  background-color: #00d1ff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.lang-button:hover {
  background-color: #f942ff;
  transform: scale(1.1);
}

.lang-button:active {
  transform: scale(1);
}
</style>
